const PROFILE_ROUTES = {
  EDIT_PROFILE: "user",
  LIST_OF_POSTS: "posts",
  SEARCH_PROFILE: "users/search",
  GET_USERS: "users",
};

export default {
  PROFILE_ROUTES,
};
